import collapse from '@alpinejs/collapse';
import focus from '@alpinejs/focus';
import barba from '@barba/core';
import Alpine from 'alpinejs';
import gsap from 'gsap';

import '/assets/styles/main.pcss';

import cardSlider from '../scripts/components/sliders/cards.js';
import imageSlider from '../scripts/components/sliders/images.js';
import keywordSlider from '../scripts/components/sliders/keywords.js';
import mediaSlider from '../scripts/components/sliders/media.js';
import { blockLink, scrollToElementOnLoad, setExternalLink, setGlossariesLink } from '../scripts/utils/url.js';
import Header from './alpine/Header.js';
import MemberCard from './alpine/MemberCard.js';
import TabPartners from './alpine/TabPartners.js';

function initAlpine() {
	Alpine.data('Header', Header);
	Alpine.data('TabPartners', TabPartners);
	Alpine.data('MemberCard', MemberCard);
	Alpine.start();
}

function init() {
	document.querySelector('body').classList.remove('overflow-hidden');

	document.querySelectorAll('[data-switch-lang]').forEach((item) => {
		item.addEventListener('click', (e) => {
			e.preventDefault();
			const lang = item.getAttribute('data-switch-lang');
			document.querySelector('html').setAttribute('lang', lang);
		});
	});

	cardSlider();
	mediaSlider();
	imageSlider();
	keywordSlider();
	setExternalLink();
	blockLink();
	scrollToElementOnLoad();
}

// init
// --------------------------------------------
window.Alpine = Alpine;

Alpine.plugin(collapse);
Alpine.plugin(focus);

document.addEventListener('DOMContentLoaded', () => {
	scrollToElementOnLoad();

	// Prevent trigger barba with links of the debug toolbar
	setTimeout(() => {
		document.querySelectorAll('#yii-debug-toolbar a').forEach((item) => {
			item.setAttribute('data-barba-prevent', 'self');
		});
	}, 1000);

	setGlossariesLink();

});

init();
initAlpine();

barba.init({
	debug: false,
	timeout: 10000,
	transitions: [
		{
			name: 'opacity-transition',
			sync: false,
			leave(data) {
				return gsap.to(data.current.container, {
					opacity: 0,
					duration: 0.5
				});
			},
			enter(data) {
				scrollToElementOnLoad();

				gsap.from(data.next.container, {
					opacity: 0,
					duration: 0.5
				});
			},
			after() {
				init();
				setGlossariesLink();
			}
		}
	]
});

